import * as storage from '@/utils/storage';
import { ICoordsContext } from '../StrategyManager';

const LatestLocationStrategy = ({ runNext, setCoords }: ICoordsContext) => {
  if (typeof window !== 'undefined') {
    const latestLocation = storage.get(storage.STORAGE_KEYS.LATEST_LOCATION);
    if (!latestLocation) {
      runNext();
      return;
    }
    const { lat, lon, zoom } = latestLocation || {};
    if (lat && lon && zoom) {
      setCoords({ lat, lon, zoom, isLocationReady: true });
    } else {
      runNext();
    }
  } else {
    runNext();
  }
};

export default LatestLocationStrategy;
