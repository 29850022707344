import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react';
import maplibre from 'maplibre-gl';
import { Map } from 'maplibre-gl';
import * as pmtiles from 'pmtiles';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as storage from '@/utils/storage';
import { ILocation, IRegion } from '@/types/explorer';
import {
  DEFAULT_MAP_TYPE,
  getMapboxAccessToken,
  getMapStyle,
  transformRequest,
  updateMap,
} from '@/utils/map';
import { useMapType } from '@/hooks/useMapType';
import { IMapOptions, IMapType } from '@/types/map';
import useCoords from '@/hooks/useCoords';
import SatelliteIcon from '@/components/ui/icons/SatelliteIcon';
import MapboxLogoIcon from '@/components/ui/icons/MapboxLogoIcon';
import { ExternalUrls } from '@/utils/url';
import InfoIcon from '@/components/ui/icons/InfoIcon';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shadcn/popover';
import UserLocation from '@/components/ui/map/UserLocation';
import GeoCoder from '@/components/ui/map/Geocoder';

let _map: Map;

interface IContext {
  map?: Map;
  setAreLayersReady: Dispatch<SetStateAction<boolean>>;
  areLayersReady: boolean;
  mapContainerRef?: React.RefObject<HTMLDivElement>;
}

const MapContext = React.createContext<IContext | null>(null);

const useMap = () => {
  const context = React.useContext(MapContext);
  if (!context) {
    throw new Error('useMap must be used within a <MapContextProvider>');
  }
  return context;
};

export interface Props {
  children?: React.ReactNode;
  regionName?: string;
  regions?: IRegion[];
  screenWidth?: number;
  mapType?: IMapType;
  country?: string;
  options?: IMapOptions;
  lon?: number;
  lat?: number;
  zoom?: number;
}

const MapContextProvider: React.FC<Props> = ({
  children,
  screenWidth,
  regionName = '',
  regions = [],
  mapType: initialMapType,
  options,
  country,
  lon: initLon,
  lat: initLat,
  zoom: initZoom,
}) => {
  const {
    enableLocation = true,
    enableStyles = true,
    enableSearch = true,
    rememberLocation = true,
    enableInteraction = true,
    maxZoom = 17,
  } = options || {};
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Map>();
  const [coords, setCoords] = useState<Partial<ILocation>>({});
  const { lat, lon, zoom, isLocationReady } = coords;
  const [currentCoords, setCurrentCoords] = useState<
    [number | undefined, number | undefined]
  >([lon, lat]); // [lon, lat]
  const { mapType, setMapType } = useMapType();
  const isMapMounted = useRef(false);
  const [areLayersReady, setAreLayersReady] = useState(false);
  const [isCenterUserLocation, setIsCenterUserLocation] = useState(false);
  const [userLat, setUserLat] = useState<number | undefined>();
  const [userLon, setUserLon] = useState<number | undefined>();
  const router = useRouter();
  const { center } = router.query;

  const onMapMoved = useCallback(
    ([lon, lat]: [number | undefined, number | undefined]) => {
      if (map) {
        const [lonCenter, latCenter] = [
          map.getCenter().lng,
          map.getCenter().lat,
        ];
        setCurrentCoords([lon || lonCenter, lat || latCenter]);
      }
    },
    [map],
  );

  useCoords({
    map: _map,
    regionName,
    regions,
    screenWidth,
    setCoords,
    country,
    rememberLocation,
    onMapMoved,
  });

  useEffect(() => {
    if (isMapMounted.current || !isLocationReady || !lon || !lat || !zoom)
      return;
    // @ts-ignore
    maplibre.accessToken = getMapboxAccessToken();
    const protocol = new pmtiles.Protocol();
    maplibre.addProtocol('pmtiles', protocol.tile);
    let finalMapType;
    if (initialMapType) {
      finalMapType = initialMapType;
    } else {
      finalMapType =
        storage.get(storage.STORAGE_KEYS.MAP_TYPE) || DEFAULT_MAP_TYPE;
      setMapType(finalMapType);
    }
    _map = new Map({
      container: mapContainerRef.current as HTMLElement,
      style: getMapStyle(finalMapType),
      center: [initLon ?? lon, initLat ?? lat],
      zoom: initZoom ?? zoom,
      minZoom: 1.2,
      maxZoom,
      transformRequest,
      interactive: enableInteraction,
    });

    if (enableInteraction) {
      const nav = new maplibre.NavigationControl({
        showCompass: false,
      });
      _map.addControl(nav, 'bottom-right');
    }

    // disable map rotation using right click + drag
    _map.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    _map.touchZoomRotate.disableRotation();

    _map.on('load', async () => {
      setMap(_map);
    });

    isMapMounted.current = true;
  }, [
    lat,
    lon,
    zoom,
    setMapType,
    isLocationReady,
    initialMapType,
    maxZoom,
    initLon,
    initLat,
    initZoom,
    enableInteraction,
  ]);

  useEffect(() => {
    if (map && !initialMapType) {
      map.setStyle(getMapStyle(mapType), {
        // this keep our current HM layers and sources when changing styles
        // keeping the correct order of layers otherwise we put them on top
        transformStyle: (previousStyle, nextStyle): any => {
          if (!previousStyle?.layers?.length) return nextStyle;
          if (!nextStyle?.layers?.length) return nextStyle;
          const hmLayers = previousStyle.layers.filter(layer =>
            layer.id.startsWith('hm-'),
          );
          if (!hmLayers.length) return nextStyle;
          const resultLayers = [];
          let hmLayerIndex = 0;
          for (const layer of nextStyle.layers) {
            while (
              hmLayerIndex < hmLayers.length &&
              previousStyle.layers.findIndex(
                l => l.id === hmLayers[hmLayerIndex].id,
              ) < previousStyle.layers.findIndex(l => l.id === layer.id)
            ) {
              resultLayers.push(hmLayers[hmLayerIndex]);
              hmLayerIndex++;
            }
            resultLayers.push(layer);
          }
          while (hmLayerIndex < hmLayers.length) {
            resultLayers.push(hmLayers[hmLayerIndex]);
            hmLayerIndex++;
          }
          const hmSources = Object.keys(previousStyle.sources)
            .filter(key => key.startsWith('hm-'))
            .reduce((obj: any, key) => {
              obj[key] = previousStyle.sources[key];
              return obj;
            }, {});

          const resultSources = { ...nextStyle.sources, ...hmSources };
          return { ...nextStyle, layers: resultLayers, sources: resultSources };
        },
      });
    }
  }, [map, mapType, initialMapType]);

  useEffect(() => {
    if (map && areLayersReady) {
      onMapMoved([undefined, undefined]);
    }
  }, [map, areLayersReady, mapType, onMapMoved]);

  useEffect(() => {
    if (center) {
      if (typeof center === 'string') {
        const center_lat = map?.getCenter().lat;
        const center_lon = map?.getCenter().lng;
        if (
          center_lat &&
          userLat &&
          userLon &&
          Number(Math.abs(userLat - center_lat).toFixed(4)) <= 0.09 &&
          center_lon &&
          Number(Math.abs(userLon - center_lon).toFixed(4)) <= 0.09
        ) {
          setIsCenterUserLocation(true);
        } else {
          setIsCenterUserLocation(false);
        }
      } else {
        setIsCenterUserLocation(false);
      }
    }
  }, [center, map, userLat, userLon]);

  const handleMapTypeClick = () => {
    if (areLayersReady) {
      if (mapType === 'light') {
        setMapType('dark');
      } else {
        setMapType('light');
      }
    }
  };

  return (
    <MapContext.Provider
      value={{ map, areLayersReady, setAreLayersReady, mapContainerRef }}>
      <div
        data-type={initialMapType ?? mapType}
        className="group/mainMap relative size-full">
        {children}
        <div className="absolute bottom-4 left-4 flex items-center gap-1 text-neutral-600 md:bottom-7">
          <Link
            href={ExternalUrls.Mapbox}
            rel="noopener noreferrer"
            target="_blank">
            <MapboxLogoIcon className="fill-current" />
          </Link>
          <Popover>
            <PopoverTrigger>
              <InfoIcon className="size-5 fill-current" />
            </PopoverTrigger>
            <PopoverContent
              data-type={initialMapType ?? mapType}
              className="flex w-auto flex-col items-center justify-center border-neutral-400 bg-neutral-900 text-xs leading-none text-neutral-0 data-[type=light]:bg-neutral-0 data-[type=light]:text-neutral-900">
              <Link
                href={ExternalUrls.Mapbox}
                rel="noopener noreferrer"
                target="_blank">
                © Mapbox
              </Link>
              <br />
              <Link
                href={ExternalUrls.OSM}
                rel="noopener noreferrer"
                target="_blank">
                © OpenStreetMap
              </Link>
              <br />
              <Link
                href={ExternalUrls.ImproveMap}
                rel="noopener noreferrer"
                target="_blank">
                Improve this map
              </Link>
            </PopoverContent>
          </Popover>
        </div>
        {enableSearch ? (
          <div className="absolute right-4 top-4 z-10">
            <GeoCoder
              proximity={currentCoords}
              onSelected={({ lat, lon, zoom, bbox }) => {
                if (map) {
                  updateMap(map, lat, lon, zoom, [0, 0], 2, bbox);
                }
              }}
            />
          </div>
        ) : null}
        {enableLocation ? (
          <div className="absolute bottom-auto right-[16px] top-[62px] md:bottom-[120px] md:top-auto">
            <UserLocation
              isCenterUserLocation={isCenterUserLocation}
              setIsCenterUserLocation={setIsCenterUserLocation}
              userLat={userLat}
              userLon={userLon}
              setUserLat={setUserLat}
              setUserLon={setUserLon}
              onSelected={({ lat, lon, zoom, bbox }) => {
                if (map) {
                  updateMap(map, lat, lon, zoom, [0, 0], 2, bbox);
                }
              }}
            />
          </div>
        ) : null}
        {enableStyles ? (
          <div
            className="absolute bottom-auto right-[16px] top-[106px] md:bottom-[180px] md:top-auto"
            onClick={handleMapTypeClick}>
            <div className="size-[32px] rounded border border-neutral-700 bg-neutral-900 p-1.5 text-neutral-0 shadow-lg transition-all hover:bg-neutral-800 group-data-[type=light]/mainMap:border-neutral-400 group-data-[type=light]/mainMap:bg-neutral-0 group-data-[type=light]/mainMap:text-neutral-900 group-data-[type=light]/mainMap:hover:bg-neutral-200 md:size-[44px] md:p-2">
              <SatelliteIcon className="fill-current" />
            </div>
          </div>
        ) : null}
      </div>
    </MapContext.Provider>
  );
};

export { useMap, MapContextProvider };
